
/**
 * Ook alle form elementen (datum ... ect) hierein. dus parameters gelijk trekken
 * Validation, rules, required fields etc. are handled in the parent @see validateForm
 * 
 * Use Form/Fields to set a form from array
 * 
 * setValues (required function) passes the values to the parent elment
 * fieldType (required string), 
 * fieldName (required string), 
 * fieldLabel (optional string), 
 * fieldValue (optional string) value / default value
 * fieldError (optional string / undefined), 
 * identifier(optional string), , 
 * fieldOptions = {} (optional object)/ additional parameter for fields, like dropdown options, date paremeters, etc
 * 
 * Format for options list select/radio etc, = array of objects wit label/value
 * [
        {label:"", value:""},
        ...
    ]

    setValues(name, value, identifier);
    sets the value as
 */
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import SignaturePad from "react-signature-canvas";
import { FormInputDate } from "./Input/Date";
import { convertOptionsList } from "./Formfunctions";
import { FormInputCategories } from "./Input/Categories";

const FormRender = ({
    setValues,
    fieldType,
    fieldName,
    fieldValue,
    fieldLabel,
    placeholder,
    fieldError,
    identifier,
    fieldOptions = {},
    info
}) => {

    const { control } = useForm();
    const [inputValue, setInputValue] = useState(fieldValue);
    const [current, setCurrent] = useState();
    const [fileList, setFileList] = useState(null);
    const [fileListBase64, setFileListBase64] = useState([]);
    const [reset, setReset] = useState(false);

    const signatureCanvas = useRef({});
    const files = fileList ? [...fileList] : [];
    const fieldId = fieldName + (identifier ?? "-form") + '-input';

    let labelClass = fieldType === 'file' ? "file-upload-button" : "form__label";

    // check options
    if (fieldType === 'select' || fieldType === 'radio') {
        if (fieldOptions[0] === undefined) {
            fieldOptions = convertOptionsList(fieldOptions);
        }

        if (fieldType === 'select') {
            fieldOptions = [{ label: "- Maak een keuze - ", value: null }].concat(fieldOptions);
        }
    }

    const handleChange = (event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        setCurrent(fieldId);
        setInputValue(value);
        setValues(fieldName, value, identifier);
    }

    const handleSelect = (option) => {
        setCurrent(fieldId);
        if (option !== undefined && option !== null) {

            let value = option.value;
            setInputValue(value);
            setValues(fieldName, value, identifier);
        }
    }

    const handleRadio = (value) => {
        setCurrent(fieldId);
        setInputValue(value);
        setValues(fieldName, value, identifier);
    }

    /** HANDLE FILE UPLOAD */
    const handleFileChange = (e) => {
        setFileListBase64([]);
        if (e.target.files) {
            setFileList(e.target.files);

            for (var i = 0; i < e.target.files.length; i++) {
                let fileBase64 = '';
                let file = e.target.files[i]

       
                getBase64(file, (result) => {
                    fileBase64 = result;

                    // Set error on +10 mb 
                    if (fileBase64.length > 10000000) {
                        file.error  = "Bestand is te groot voor upload via de app";

                        setReset(reset === false);
                        return;  
                    }
                    else {
                        setFileListBase64((fileListBase64) => [...fileListBase64, { 'file': fileBase64, 'name': file.name }]);
                    }
                });
            };
        }
        setCurrent(fieldId);
    };

    useEffect(() => {
        if (fileListBase64.length > 0) {
            setInputValue(JSON.stringify(fileListBase64));
            setValues(fieldName, JSON.stringify(fileListBase64), identifier);
        }
    }, [fileListBase64, fieldName, identifier, setValues])

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleSignature = () => {

        let signature = null;

        if (signatureCanvas.current !== undefined && !signatureCanvas.current.isEmpty() && Object.keys(signatureCanvas.current).length > 0) {
            signature = signatureCanvas.current.getCanvas().toDataURL('image/png');
        }
        setValues(fieldName, signature, identifier);
    }

    const clearSignature = (e) => {

        e.preventDefault();

        if (Object.keys(signatureCanvas.current).length > 0) {
            signatureCanvas.current.clear();
        }
    }


    if (current !== fieldId && inputValue !== null && (fieldValue === undefined || fieldValue === 0) && inputValue !== fieldValue) {

        setInputValue(null);
        if (document.querySelector(`#` + fieldId) !== null) {
            document.querySelector(`#` + fieldId).value = "";
        }
    }

    // Reset parameters
    let fieldContent;
    if (fieldType === 'checkbox') {
        fieldContent = fieldLabel
        fieldLabel = fieldOptions !== undefined ? fieldOptions.label : null;
    }

    const getDefaultOption = (defaultValue, list) => {

        if (defaultValue === undefined) {
            return;
        }

        let result;
        Object.keys(list).forEach((option) => {
            if (String(list[option]['value']) === String(defaultValue)) {
                result = list[option];
            }
        })
        return result;
    }

    return (<>

        {/** Date has custom wrapper @todo: make wrapper dymanic zo label & errors can be rendered from here */}
        {fieldType === 'date' &&
            <div className="form__component">
                <FormInputDate
                    setValues={setValues}
                    fieldName={fieldName}
                    fieldValue={fieldValue}
                    fieldLabel={fieldLabel}
                    fieldError={fieldError}
                    identifier={identifier}
                    range={fieldOptions}
                />
            </div>
        }
        
        {fieldType === 'title' && (
            <div className={"form__element form__element--" + fieldType}>
                <h4>{fieldLabel}</h4>
            </div>
        )}

        {fieldType !== 'date' && fieldType !== 'location' && fieldType !== 'title' && fieldType !== 'hidden' && (

            <div className="form__component">

                {(fieldError || fieldLabel) &&
                    <label className={labelClass} htmlFor={fieldId}>
                        {fieldError &&
                            <span style={{ color: 'red' }}>{fieldError}: </span>
                        }
                        {fieldLabel}
                        {info !== undefined && info !== null &&
                            <span className="form-field-info"><br />{info}</span>
                        }
                    </label>
                }
                <div className={"form__element form__element--" + fieldType}>

                    {fieldType === 'text' &&
                        <input
                            id={fieldId}
                            type="text"
                            placeholder={placeholder}
                            onChange={handleChange}
                            defaultValue={fieldValue}
                        />
                    }

                    {fieldType === 'select' &&
                        <Controller
                            control={control}
                            id={fieldId}
                            name={fieldName}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <Select
                                    key={`my_unique_select_key__${fieldValue}`}
                                    //value={getDefaultOption(fieldValue, fieldOptions)}
                                    defaultValue={getDefaultOption(fieldValue, fieldOptions)}
                                    isClearable
                                    placeholder={"- Maak een keuze -"}
                                    name={fieldName}
                                    options={fieldOptions}
                                    onChange={handleSelect}
                                />)}
                        />
                    }

                    {fieldType === 'checkbox' &&
                        <>
                            <input
                                type="checkbox"
                                id={fieldId}
                                onChange={handleChange}
                                defaultChecked={fieldValue}
                            />
                            <label htmlFor={fieldId}>{fieldContent}</label>
                        </>
                    }

                    {fieldType === 'textarea' &&
                        <textarea
                            id={fieldId}
                            onChange={handleChange}
                            placeholder={placeholder}
                        >
                            {fieldValue}
                        </textarea>
                    }
                    {fieldType === 'radio' &&
                        <div className="form__row form__row--fieldset">
                            {fieldOptions.map((option) =>
                                <div className="form__component form__component--auto" key={fieldId + option.value}>

                                    <div className="form__element">
                                        <div className="form__input" onClick={() => handleRadio(option.value)}>
                                            <input
                                                type="radio"
                                                checked={inputValue === option.value}
                                                onChange={() => handleRadio(option.value)} ///doet t niet ???!!!
                                            />
                                            <label htmlFor={fieldId}>{option.label}</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                    {fieldType === 'file' &&
                        <>
                            <input id={fieldId} type="file" className="file-upload" onChange={handleFileChange} multiple />
                            <ul className="file-upload-list">
                                {files.map((file, i) => (
                                    <li key={i} className={"file-upload " + (file.error ? "file-upload-error" : "file-upload-selected")}>
                                        {file.error !== undefined &&
                                            <strong>{file.error}: </strong>
                                        }
                                        {file.name} - {file.type}
                                    </li>
                                ))}
                            </ul>
                        </>
                    }

                    {fieldType === 'signature' &&
                        <>
                            <SignaturePad
                                ref={signatureCanvas}
                                canvasProps={{ className: "signatureCanvas", height: 250 }}
                                clearOnResize={false}
                                onEnd={() => handleSignature(fieldName)}
                            />
                            <div className="form__row form__row--buttons">
                                <div className="form__component">
                                    <div className="form__element">
                                        <button className={"button button--primary clear"} onClick={(event) => clearSignature(event)}>Wissen</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {fieldType === 'categories' &&
                        <FormInputCategories
                            fieldName={fieldName}
                            fieldValue={fieldValue ?? {}}
                            setValues={setValues}
                            output={fieldOptions.output}
                            fields={fieldOptions}
                            identifier={identifier}
                        />
                    }
                </div>
            </div>

        )}
    </>)
}

export { FormRender } 